const $ = require('jquery');
global.$ = global.jQuery = $;

require('popper.js');
require('moment/moment');
require('moment/locale/fr');
require('bootstrap');
require('datatables.net-bs4');
const bsCustomFileInput = require('bs-custom-file-input');
require('daterangepicker');
require('eonasdan-bootstrap-datetimepicker');
require('select2');
require('select2/dist/js/i18n/fr');
require('admin-lte');
require('cocoon-js');

$(function() {
  bsCustomFileInput.init();
});

const toastr = require('toastr');
toastr.options = {
  closeButton: true,
  newestOnTop: true,
  progressBar: true,
  preventDuplicates: true
};
global.toastr = toastr;

require('../stylesheets/adminlte.scss');

require('admin-lte/dist/img/AdminLTELogo.png');
require('admin-lte/dist/img/user1-128x128.jpg');
require('admin-lte/dist/img/user2-160x160.jpg');
require('eonasdan-bootstrap-datetimepicker/build/css/bootstrap-datetimepicker.min.css');
